.contacts-title {
  background-color: $mainBckg;
}
.contact{
  &-section{
    background-color: $mainBckg;
    margin-bottom: 7px;
  }
}
.contacts{
  &-title{
    padding: 15px 0 12px;
  }
  &-item{
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
    &-title{
      font-size: 12px;
      line-height: 1;
      font-weight: 800;
      color: #000;
      margin-bottom: 3px;
    }
    &-desc{
      margin-bottom: 5px;
    }
  }
  &-content{
    padding: 14px 0 18px;
  }
}
.map{
  background-image: url(../img/contact-map.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100% ;
  height: calc(100vw - 20px);
}

.social{
  height: 102px;
  &-list{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  &__link{
    background: url(../img/socials.png) no-repeat;
    width: 31px;
    height:31px;
    display: inline-block;
    margin: 0 5px;
    transition: opacity .3s;
    &:hover{
      opacity: .7;
    }
    &:active{
      opacity: .5;
    }
    &--facebook{
      background-position: 0px 0px;
    }
    &--twitter{
      background-position: 0px -42px;
    }
    &--instagram{
      background-position: 0px -83px;
    }
    &--pinterest{
      background-position: 0px -125px;
    }
  }
}

@media screen and (min-width: 700px){
  .contacts{
    &-list{
      display: flex;
      margin: 0 -5px;
    }
    &-item{
      width: 33%;
      padding: 0 5px;
    }
  }
}