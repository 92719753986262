.positions{
  &-title{
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 30px;
    background-color: $mainBckg;
    margin-bottom: 7px;
  }
}
.position{
  width: 100%;
  background-color: $mainBckg;
  margin: 0 0 7px;
  min-height: 178px;
  padding: 33px 29px 25px 24px;
  font-size: 12px;
  line-height: normal;
  &__title{
    font-weight: 600;
    margin-bottom: .5em;
  }
  &__address{
    font-weight: 600;
    margin-bottom: .5em;
  }
  &__desc{
    p{
      margin: .5em 0;
    }
  }
}

@media screen and (min-width: 600px){
  .position{
    font-size: 16px;
  }
}