.footer{
  height: 38px;
  line-height: 38px;
  margin: 7px 0;
  font-size: 12px;
  color: #EB7C5E;
  .container{
    display: flex;
    align-items: center;
    height: 100%;
    max-width: calc(100% - 30px);
  }
  &-time{
    //margin-left: 22px;
    width: 53%;
    padding-left: 25px;
    background-image: url(../img/clock.png);
    background-repeat: no-repeat;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    background-position: left 1px;
  }
  &-link{
    padding-left: 25px;
    background-image: url(../img/map.png);
    background-position: left center;
    background-repeat: no-repeat;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    width: 47%;
  }
}

@media screen and (max-width: 360px){
  .footer{
    font-size: 10px;
    &-item{
      padding-left: 18px;
      background-size: 14px;
      background-position: left center;
    }
  }
}

@media screen and (min-width: 400px){

}
