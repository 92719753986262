* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: baseline;
}

html, body {
  overflow-x: hidden;
}

:before, :after {
  box-sizing: inherit;
}

[required], :focus, :active {
  outline: none;
  box-shadow: none;
}

::-moz-focus-inner {
  border: 0;
}

textarea {
  resize: none;
  overflow: auto;
}

a, input, label, button, select, textarea {
  cursor: pointer;
}

input, label, button, select, textarea {
  font: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

address {
  font-style: normal;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

:focus::-webkit-input-placeholder {
  color: transparent;
}

:focus::-moz-placeholder {
  color: transparent;
  opacity: 0;
}

ul, ol {
  list-style: none;
}

img {
  max-width: 100%;
  max-height: 100%;
}

svg {
  display: inline-block;
}

path, circle, rect {
  fill: inherit;
}
body{
  margin: 0;
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 0;
  height: 0;
  overflow: hidden;
}
