// what need know
.school-info{
  padding-bottom: 5px;
  &__title {
    margin-bottom: 25px;
    margin-top: 8px;
  }
}

.school-info-item{
  font-size: 12px;
  line-height: normal;
  margin-bottom: 2em;
  &__title{
    font-weight: 600;
    margin-bottom: .4em;
    &--pad{
      @extend .school-info-item__title;
      padding-left: 18px;
    }
  }
  &__text{
    position: relative;
    padding-left: 18px;
    margin-bottom: .4em;
    span{
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.schedule{
  &__item{
    padding-left: 90px;
  }
  .school-info__title{
    width: 60%;
  }
}
.preparation-info{
  padding-bottom: 5px;
}
.list-ul{
  font-size: 12px;
  &__item, li{
    position: relative;
    padding-left: 18px;
    //danger
    margin-bottom: .5em;
    &:before{
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      background-color: #F9B513;
      width: 7px;
      height: 7px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
    p{
      font-weight: 600;
      line-height: 15px;
    }
  }
}

.garden{
  &-info{
    padding-bottom: 10px;
  }
  .school-info{
    &-item{
      p{
        margin-bottom: .5em;
      }
    }
    &__title{
      width: 73%;
    }
  }
  h3{
    font-size: 12px;
    margin: 1em 0 .666em;
  }
  h4, h5{
    font-size: 12px;
    padding-left: 15px;
    margin: 1em 0 .041em;
  }
  ol{
    list-style: decimal;
    padding-left: 15px;
    margin: .666em 0 .583em;
    li{
      margin-bottom: 4px;
    }
  }
  ul{
    li{
      position: relative;
      padding-left: 75px;
      margin-bottom: 4px;
    }
    strong{
      position: absolute;
      left: 0;
      display: inline-block;
      font-weight: 400;
      width: 75px;
    }
  }
}


@media screen and (min-width: 600px){
  .school-info-item{
    font-size: 16px;
  }
  .garden{
    h3, h4, h5{
      font-size: 16px;
    }
    ol{

    }
    ul{
      li{
        padding-left: 100px;
      }
      strong{
        width: 100px;
      }
    }
  }
}
@media screen and (min-width: 700px){
  .list-ul {
    font-size: 16px;
  }
}