.gallery{
  &-section{
    background-color: $mainBckg;
    margin-bottom: 7px;
    padding: 15px 0 12px;
    p{
      text-transform: uppercase;
      width: 70%;
      font-size: 12px;
      font-weight: 600;
    }
  }
  &-page-title{
    font-weight: 900;
    line-height: 1;
    font-size: 36px;
    margin-bottom: .1em;
  }
  &-content{
    background-image: none;
  }
  &-item{
    @extend .school-content-item;
    width: 100%;
    order: 0;
    padding-right: 10px;
    padding-left: 10px;
    //transition: all .65s;
    transition-duration: .65s;
    &__title{
      text-align: left;
      position: static;
      .active &{
        margin-bottom: 37px;
      }
    }
    &__link{
      display: flex;
      position: relative;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      width: 100%;
      max-width: calc(100% - 33px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 24px;
      text-transform: uppercase;
      color: #000;
    }
    &__btn{
      width: 18px;
      height: 40px;
      right: 6px;
      bottom: 9px;
    }
    &.collapsed-item{
      margin: 0 0 7px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }

  &-album{
    font-size: 12px;
    line-height: normal;
    margin-bottom: 1.5em;
    position: relative;
    &__title{
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      a{
        display: block;
      }
    }
    &__date{
      font-size: 12px;
      font-weight: 400;
    }
    &__btn{
      position: absolute;
      right: 0;
      top: 4px;
      width: 8px;
      height: 16px;
      background-color: transparent;
      background-image: url(../img/left-arrow.png);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  &-city{
    min-height: calc(100vh - 128px);
  }
}
.hasActive .gallery-item:not(.active){
  max-height: 0;
  overflow: hidden;
  padding: 0;
  font-size: .1em;
  margin: 0;
  transition: all .65s;
}
.active.gallery-item{
  order: 0;
  padding-right: 10px;
  padding-left: 10px;
  .gallery-item__title{
    margin-bottom: 37px;
    position: relative;
  }
  .gallery-item__btn{
    right: 7px;
    bottom: -10px;
    width: 16px;
  }
}

.instagram{
  &-btn{
    height: 79px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      position: relative;
      font-size: 10px;
      text-transform: uppercase;
      line-height: 12px;
      color: #000;
      font-weight: 400;
      &:before{
        content: '';
        position: absolute;
        right: calc(100% + 13px);
        top: 50%;
        transform: translateY(-50%);
        width: 31px;
        height: 31px;
        background-image: url(../img/instagram.svg);
        background-size: 31px;
        background-position: center;
      }
    }
  }
  &-list{
    display: flex;
    //justify-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &__item{
    width: calc(33.33vw - 2px);
    height: calc(33.33vw - 2px);
    background-color: #C4C4C4;
    &:nth-child(3n+2){
      margin: 0 3px 3px;
    }
  }
}

.photo{
  &-list{
    @extend .instagram-list;
  }
  &__item{
    @extend .instagram__item;
    img{
      display: block;
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (min-width: 700px) {
  .gallery-album__title {
    font-size: 14px;
  }
  .active.gallery-item .gallery-item__btn{
    left: -10px;
    right: auto;
  }
}