.header{
  width: 100vw;
  transition: transform .4s;
  &-top{
    padding: 9px 0;
    display: flex;
    align-items: center;
    &__menu{
      //margin-left: auto;
    }
  }
  &-bottom{
    padding: 0 18px;
    background-color: #EB7C5E;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 10px;
    text-transform: uppercase;
  }

  &-phones{
    padding-left: 33px;
    background-image: url(../img/phone.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    &-item{
      display: inline-block;
      margin-right: 22px;
    }
  }

}

.logo{
  width: 116px;
  height: 44px;
  margin-right: auto;
}

.blog-btn{
  background-color: #f87c5b;
  font-weight: 700;
  color: #fff;
  height: 18px;
  line-height: 18px;
  font-size: 14px;
  display: block;
  border: none;
  text-transform: uppercase;
  padding: 0 8px;
  margin-right: 22px;
}

.user{
  position: relative;
  height: 17px;
  width: 16px;
  margin-right: 20px;
  font-size: .9em;
  html[lang='ru-RU'] &{
    font-size: .75em;
  }
  &__avatar{
    height: 17px;
    width: 16px;
    border: none;
    background-image: url(../img/user.svg);
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &__text {
    display: none;
    opacity: 0;
    transform: translateY(-120px);
    transition: .4s;

    &:before{
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 18px 9px;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      top: -18px;
      left: 68px;
      z-index: 1;
    }
    &:after{
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 19px 10px;
      border-color: transparent transparent rgba(28, 28, 28, 0.52) transparent;
      position: absolute;
      top: -19px;
      left: 67px;
      content: '';
      z-index: 0;
    }
  }
  &.active &__text{
    display: block;
    opacity: 1;
    position: absolute;
    transform: translateY(15px);
    background-color: #fff;
    padding: 10px;
    left: -72px;
    z-index: 101;
    border-radius: 4px;
    border: 1px solid rgba(28, 28, 28, 0.32);
  }
  &__login{

  }
  &__signin{

  }
}

.messages{
  &-btn{
    display: block;
    background-image: url(../img/email.png);
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    width: 30px;
    height: 19px;
  }
}

.nav-label{
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: none;
  height: 20px;
  width: 24px;
  span{
    transition: all .5s ease;
    display: block;
    width: 20px;
    height: 2px;
    background-color: #EB7C5E;
    margin-top: 3px;
    &+span {
      //margin-top: 5px;
    }
  }
}
.nav{
  //display: none;
}

.section-info{

}

.lng{
  &__link{
    &.active{
      font-size: 8px;
    }
  }
}

//menu start
.nav{
  position: fixed;
  top: 0;
  left: 0;
  right: 55px;
  bottom: 0;
  min-height: 100vh;
  transform: translateX(calc(-100vw + 56px));
  background-color: #F9B513;
  padding: 15px 30px 15px 20px;
  display: flex;
  //align-items: center;
  //justify-content: center;
  flex-direction: column;
  font-size: 1.5em;
  line-height: 2.2;
  transition: all .4s ease;
  opacity: 0;
  visibility: hidden;
  color: hsla(0,0%,100%,.95);
  //transform: translateX(-100%);
  .overflow &{
    opacity: 1;
    visibility: visible;
    //transform: translateX(0);
    z-index: 110;
  }
}
.menu{
  padding: 0;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 18px;
  color: #FFFFFF;
  &-item{
    margin-bottom: 32px;
    padding-left: 44px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    &__about{
      background-image: url(../img/icon-about.png);
    }
    &__school{
      background-image: url(../img/icon-school.png);
    }
    &__sadok{
      background-image: url(../img/icon-sadok.png);
    }
    &__group{
      background-image: url(../img/icon-group.png);
      background-position-y: 4px;
      background-position-x: -4px;
    }
    &__price{
      background-image: url(../img/icon-price.png);
    }
    &__gallery{
      background-image: url(../img/icon-gallery.png);
    }
    &__another{
      background-image: url(../img/icon-another.png);
    }
    &__contact{
      background-image: url(../img/icon-contact.png);
    }
  }
  &-link{
    transition: .3s;
    &:hover{
      opacity: .7;
    }
    &:active{
      opacity: .5;
    }
  }
}
.search{
  margin-bottom: 24px;
  &-form{
    position: relative;
  }
  &-input{
    border: none;
    border-bottom: 1px solid #fff;
    padding: 5px 10px 5px 43px;
    background-color: transparent;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    &::-webkit-input-placeholder{
      color: #fff;
    }
  }
  &-btn{
    position: absolute;
    bottom: calc(50% - 12px);
    left: 0;
    display: inline-block;
    width: 25px;
    height: 25px;
    background-image: url(../img/search.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
  }
}
//menu end

@media screen and (max-width: 360px){
  .blog-btn{
    margin-right: 15px;
  }
  .user{
    margin-right: 15px;
  }
}

@media screen and (min-width: 400px){

}
@media screen and (min-width: 600px){
  .header{
    &-top{
      &__menu{
        margin-left: 0px;
      }
    }
  }
}

