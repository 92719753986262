.lightbox {
  cursor: pointer;
  position: fixed;
  width: 100%;
  height: calc(100vh - 128px);
  top: 90px;
  left: 0;

  background: #616161;
  -webkit-filter: none !important;
  z-index: 100;
  transition: transform .4s;
}
.blurred {
  overflow: hidden;
  position: fixed;
}

.lightbox img {
  display: block;
  position: absolute;
  //border: 5px solid #fff;
  //box-shadow: 0 0 20px #000;
  border-radius: 1px;
  top: calc(50% - 20px) !important;
  transform: translateY(-50%);
  width: 100% !important;
  max-width: 100% !important;
  height: auto !important;
  left: 0 !important;
}

//body.blurred > * {
//  -webkit-filter: blur(2px);
//  -webkit-transform: translate3d(0, 0, 0)
//}

.lightbox__loading {
  //background: url(images/loading.gif) center center no-repeat;
  content: 'Загрузка фото ...';
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
  position: absolute;
  top: 50%;
  left: 50%
}

.lightbox__caption {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 9998;
  background: #000;
  background: rgba(0, 0, 0, .7)
}

.lightbox__caption p {
  margin: 0 auto;
  max-width: 70%;
  display: inline-block;
  padding: 10px;
  color: #fff;
  font-size: 12px;
  line-height: 18px
}

.lightbox__button {
  position: absolute;
  z-index: 9999;
  background: center center no-repeat;
  width: 32px;
  height: 32px;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  transition: all .3s
}

.lightbox__close {
  right: 5px;
  top: 12px;
  background-image: url(../img/close.png)
}

.lightbox__nav {
  top: 50%;
  top: calc(50% - 20px);
  margin-top: -16px
}

.lightbox__nav--next {
  right: 10px;
  background-image: url(../img/arrow-left--white.png)
}

.lightbox__nav--prev {
  left: 10px;
  background-image: url(../img/arrow-left--white.png);
  transform: rotate(180deg);
  transform-origin: 50% 60%;
}