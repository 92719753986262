
.content-menu{
  &__list{
    display: flex;
    flex-wrap: wrap;
    margin: 7px 0;
    justify-content: space-between;
  }
  &__item{
    width: calc(50vw - 4px);
    width: calc(50% - 3.5px);
    height: calc(50vw - 4px);
    height: calc(50vw - 7px);
    text-align: center;
    //background-color: #f9b513;
    padding: 0;
    margin-bottom: 7px;
  }
  &__link{
    background-color: #f9b513;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
    &:hover, &:focus{
      background-color: #eb7a5c;
    }
    &:active{
      background-color: darken(#eb7a5c, 15%);
    }
  
  }
}

.school-menu{
  &__link{
    & strong{
      display: block;
      line-height: 1;
      font-weight: 900;
      font-size: 36px;
      width: 100%;
      text-align: center;
      position: relative;
    }
    & span{
      display: block;
      text-align: center;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 400;
    }
  }
}
.school-content{
  &-item{
    background-color: #FAF4E6;
    position: relative;
    margin-bottom: 7px;
    height: 178px;
    padding: 14px;
  }
  &__title{
    height: 100%;
    width: 100%;
  }
  &__link{
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    //width: 69%;
    padding-right: 31%;
    //top: -10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 24px;
    text-transform: uppercase;
    color: #000;
  }
  &__arrow{
    position: absolute;
    width: 14px;
    height: 30px;
    display: inline-block;
    background-color: transparent;
    background-image: url(../img/left-arrow.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 10px;
    right: 13px;
  }
}
.lm-content--klass{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 12px;
  color: #000000;
  padding: 0;
  p{
    margin-bottom: 1em;
  }
}
.school-class{
  padding-bottom: 5px;
  &-title{
    margin-bottom: 4px;
  }
  &__hero{
    margin-bottom: .5em;
  }
}
