table{
  border-spacing: 5px;
  border-collapse: separate;
}
.prices-page--title{
  background-color: #FAF4E6;
  height: 77px;
}
.prices-title{
  //@extend .container;
  display: flex;
  height: 100%;
  align-items: center;
  font-weight: 900;
  line-height: 1;
  font-size: 36px;
  text-transform: uppercase;
  span{
    display: inline-block;
    width: 150px;
    color: #2c2c2c;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    text-transform: uppercase;
    margin-left: 15px;
  }
}
.price{
  &-block{
    background-color: #FAF4E6;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
    padding-bottom: 14px;
    &__title{
      background-color: #fff;
      font-size: 24px;
      line-height: 1.125;
      padding: 10px 0;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      span, address{
        display: block;
        font-size: 11px;
        font-weight: 400;
      }
      address{
        margin-top: .35em;
        text-transform: none;
      }
    }
    h4{
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      margin-bottom: 1em;
      padding: 0 30px;
      //font-weight: 500;
    }
    p{
      margin-bottom: 1em;
      strong+span{
        display: block;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
    li{
      margin-bottom: 1em;
    }

    &__add-info{
      ul{
        margin-bottom: 3em;
      }
    }
  }
  &-table{
    background-color: #FAF4E6;
    padding: 8px 0 0 13px;
    margin-bottom: 1em;
    strong{
      text-transform: uppercase;
      font-size: 1.1818em;
    }
    &__name{
      width: 80px;
    }
    &__age{
      width: 60px;
    }
    &__desc{
      width: 70px;
    }
    &__cost{
      width: 70px;
    }
  }
}

.after-price {
  margin-bottom: 2em;
  width: 75%;
  p{
    font-weight: 600;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 320px){
  .price{
    &-block{
      font-size: 9px;
    }
  }
}
@media screen and (min-width: 600px){
  .price{
    &-block{
      font-size: 14px;
    }
    &-table{
      &__desc{
        width: 120px;
      }
    }
  }
}
@media screen and (min-width: 700px){
  table{
    border-spacing: 10px;
  }
  .price{
    &-block{
      font-size: 16px;
      line-height: normal;
      &__title{
        font-size: 26px;
        padding: 14px 0;
      }
      h4{
        font-size: 16px;
      }
      li{
        margin-bottom: .5em;
      }
    }
  }
}