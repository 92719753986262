.hero{
  &-img{
    width: 100%;
    //height: 30.933vh;
    display: block;
  }
}

.features{
  &__list{

  }
  &__item{
    margin-bottom: 1.5em; // 24px
  }
}
.defis{
  display: inline-block;
}
.about-bckg{
  background-image: url(../img/about-bckg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 67.73vw;
}

.review {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  &__photo {
    padding: 5px;
    padding-right: 20px;
    //width: 25%;
    img {
      border-radius: 50%;
      width: 84px;
      height: 84px;
    }
  }
  &__meta {
    //width: 75%;
    padding-top: 1em;
    max-width: 65%;
  }
  &__name {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 9px;
    color: #000;
    margin-bottom: 0px;
  }
  &__stars {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }
  &__date{
    margin-left: 6px;
    font-size: 9px;
  }
  &__text{
    font-size: 9px;
  }
}
.reviews{
  &-form{
    position: relative;
    margin-bottom: 17px;
  }
  &-textarea{
    width: 100%;
    background-color: transparent;
    border: 1px solid #000000;
    padding: 11px 25px;
    font-size: 9px;
    text-transform: uppercase;
    border-radius: 0;
    -webkit-appearance: none;

  }
  &-textfield {
    width: 100%;
    background-color: transparent;
    border: 1px solid #000000;
    padding: 11px 25px;
    font-size: 9px;
    text-transform: uppercase;
    border-radius: 0;
    -webkit-appearance: none;
    margin-bottom: .875em;
  }
  &-btn{
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    background-image: url(../img/btn-smile.png);
    background-size: 30px;
    background-color: transparent;
    border: none;
    position: absolute;
    bottom: 14px;
    right: 14px;
  }
  &__total{
    border-bottom: 1px solid #000;
    padding: 0 0 8px 8px;
    font-size: 9px;
    line-height: 1;
    font-weight: 600;
    margin-bottom: 30px;
  }
}
.reviews-more{
  display: block;
  width: 50px;
  height: 60px;
  background-image: url(../img/bottom-arrow.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  margin: 0 auto;
}
.rating {
  display: inline-flex;
}

.teachers{
  &.active{
    padding: 0;
    .collapsed-item__title,
    .collapsed-item-content{
      padding: 0 13px 20px 24px;
    }
    .defis{
      display: none;
    }
  }
  .collapsed-item__title{
    padding-left: 17px;
    text-align: left;
  }

}

.teachers-filter{
  display: none;
  flex-wrap: wrap;
  margin-bottom: 27px;
  .active &{
    display: flex;
    background-color: #fff;
    padding: 6px;
  }
  &__item{
    width: 50%;
    height: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    line-height: 18px;
    font-size: 18px;
    background-color: #F9B513;
    &--active{
      background-color: #EB7C5E;
    }
  }
}

.teacher{
  display: flex;
  font-size: 9px;
  margin-bottom: 37px;
  &__photo{
    min-width: 142px;
    height: 142px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: 19px;
    img{
      width: 142px;
      height: 142px;
      object-fit: cover;
    }
  }
  &__name{
    text-transform: uppercase;
    margin-bottom: 3px;
  }
  &__job{
    font-weight: 600;
    text-transform: lowercase;
    margin-bottom: 14px;
  }
}

.reviews{
  &-mark{
    display: none;
    .active &{
      display: inline;
    }
  }
}
.review-hidden{
  display: none;
}

.rating{
  &-review {
    display: inline-flex;
    flex-direction: row-reverse;
    position: relative;
    top: -15px;
    &>input:checked~label {
      background-image: url(../img/star.svg);
    }
  }
  input{
    display: none;
  }
  &-item {
    height: 16px;
    width: 16px;
    background-image: url(../img/star-empty.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transition: 0s;
  }
}

@media screen and (max-width: 360px){

}

@media screen and (min-width: 400px){

}
@media screen and (min-width: 600px){
  .defis{
    display: none;
  }
  .teacher{
    font-size: 14px;
  }
  .review{
    &__text, &__name{
      font-size: 14px;
    }
  }
}
@media screen and (min-width: 700px){
  .review {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 27px;
    &__photo {
      img {
        width: 105px;
        height: 105px;
      }
    }
  }
}

.hidden{
  display: none;
}