.list-ol{
  &__item{
    position: relative;
    padding-left: 18px;
    span{
      font-size: 12px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.child-club{
  &-content{
    padding-bottom: 10px;
  }
}