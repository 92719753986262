.garden-page{
  padding-bottom: 1px;
  .hero-title{
    width: 70%;
    margin-bottom: .4em;
  }
  .hero__text{
    margin-bottom: 14px;
    span{
      display: block;
    }
  }
  .lm-content{
    padding-bottom: 10px;
  }
  .lm-content-sub-title{
    width: 75%;
  }
  .list-ul{
    li{
      padding-left: 18px;
      //font-size: 12px;
      margin-bottom: 1em;
    }
  }
  .collapsed-wrapper{
    background-image: none;
  }
  .collapsed-item{
    width: 100%;
    margin: 0 0 7px;
    &:last-child{
      margin-bottom: 0;
    }
    &.active{
      order: 1;
    }
    &__title{
      justify-content: flex-start;
      text-align: left;
    }
  }
}


.garden{
  &-menu{
    &__link{
      font-size: 14px;
      line-height: 14px;
      padding: 0px 15px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
  &-content-item{
    height: 178px;
    padding: 14px;
    p{
      margin-bottom: 0.5em;
      font-size: 12px;
    }
    h5, h4{
      margin: 1em 0 5px;
      font-size: 12px;
      line-height: 15px;
    }
    ol{
      margin-bottom: 1em;
      font-size: 12px;
      list-style-type: decimal;
      padding-left: 15px;
      margin-bottom: 10px;
      li{
        margin-bottom: 0.5em;
      }
    }
    ul{
      margin-bottom: 1.5em;
      font-size: 12px;
      li{
        
      }
    }

    .collapsed-item__btn{
      bottom: -4px;
      right: -4px;
    }
  }
  &-content__part{
    margin-bottom: 20px;
  }
  &-schedule{
    &__item{
      margin-bottom: 20px;
    }
  }
}
@media screen and (min-width: 700px){
  .garden {
    &-menu {
      &__link {
        font-size: 18px;
      }
    }
  }
}