.other{
  &-position{
    @extend .school-content-item;
    margin-bottom: 7px;
    &__link{
      display: flex;
      position: relative;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      font-size: 24px;
      text-transform: uppercase;
      color: #000;
      top: 0;
      &-img{
        margin-right: 26px;
      }
    }
  }
  &-content{
    padding-bottom: 10px;
  }
}

p.attention_text{
  margin-bottom: 1em;
}

.partners{
  padding: 54px 10px 22px 28px;
  &-list .textwidget{
    display: flex;
    justify-content: space-around;
  }
  &-link{
    display: flex;
    width: 30%;
    align-items: flex-end;
  }
  &-img{

  }
}


.blog-section-title {
  background-color: #FAF4E6;
  height: 74px;
  margin-bottom: 7px;
  padding-left: 17px;
  padding-top: 14px;
}

.blog-section-title .gallery-page-title {
  display: flex;
  height: 100%;
  align-items: flex-start;
  font-weight: 900;
  line-height: 1;
  font-size: 36px;
  text-transform: uppercase;
}

section.blog-section-list {
  background-color: #FAF4E6;
  background-color: #FAF4E6;
  padding: 14px 0 21px;
  margin-bottom: 5px;
}

.blog-section-list article {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 11px;
}
.blog-section-list time {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
}
.blog-section-list a {
  width: 100%;
  display: block;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    right: 0;
    top: 4px;
    width: 8px;
    height: 16px;
    background-color: transparent;
    background-image: url(../img/left-arrow.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.blog-section-list span {
  max-width: 80%;
  display: inline-block;
}

.not-found {
  min-height: calc(100vh - 142px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1{
    font-size: 3em;
  }
  h2{
    color: #ec7d5b;
    font-size: 3em;
  }
}