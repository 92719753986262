.garden-group{
  padding-bottom: 5px;
  .hero-title{
    width: 80%;
  }
}
.lm-content-group{
  padding-top: 5px;
  padding-bottom: 1px;
  h3{
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: .5em;
    margin-top: 1em;
  }
  p{
    margin-bottom: 1em;
  }
}